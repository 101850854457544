import { ArrowRightIcon } from 'lucide-react'
import { Link } from 'react-router-dom'

import { useMerchantDashboard } from '@/api/hooks/use-merchant-dashboard'
import { Button } from '@/components/ui'
import { MyPermissions } from '@/constants/my-permissions'
import { useAuthorization } from '@/hooks/use-authorization'
import { MerchantDashboardValueCard } from '@/modules/merchant/dashboard/merchant-dashboard-value-card'
import { MerchantRecentTransactionsTable } from '@/modules/merchant/dashboard/merchant-home-transactions-table'
import { formatCentsToCurrency } from '@/utils/money-utils'

export function MerchantHomePage() {
	const { isAuthorized: canCheckout } = useAuthorization(undefined, [MyPermissions.CreateACHDebit])
	const { isLoading, data: dashboardData } = useMerchantDashboard()

	return (
		<div className="container space-y-4 p-4 xl:p-8">
			{canCheckout && (
				<Button
					asChild
					className="hover:bg-blue-linear/80 typography-title-large flex h-auto w-auto max-w-none flex-col items-center justify-center gap-2 rounded-2xl bg-blue-linear p-8 text-white [&_svg]:size-6"
				>
					<Link to="/merchant/checkout">
						<span>Start New Transaction</span>
						<ArrowRightIcon />
					</Link>
				</Button>
			)}
			<div className="flex w-full flex-col gap-4 *:flex-1 sm:flex-row">
				<MerchantDashboardValueCard
					isLoading={isLoading}
					label="MTD Total Sales"
					value={formatCentsToCurrency(dashboardData?.MonthlySales)}
					data-testid="total-monthly-sales"
				/>
				<MerchantDashboardValueCard
					isLoading={isLoading}
					label="MTD Total Transactions"
					value={dashboardData?.MonthlyTotalTransactions || 0}
					data-testid="total-transactions"
				/>
			</div>

			<div
				className="rounded-lg bg-card p-4"
				data-testid="merchant-home-transactions-table"
			>
				<div className="typography-title-large pb-2 text-primary">Recent Transactions</div>

				<MerchantRecentTransactionsTable />
			</div>
		</div>
	)
}
