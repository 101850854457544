import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useActivateAccount } from '@/api/hooks/use-activate-account'
import { useDeactivatedUser } from '@/api/hooks/use-get-deactivated-user'
import BodyClassName from '@/components/body-class-name'
import { Loading } from '@/components/loading'
import NeedHelpLink from '@/components/need-help-link'
import ThemeColor from '@/components/theme-color'
import { Button } from '@/components/ui'
import RedirectPage from '@/pages/redirect-page'
import { getLogoutOptions } from 'src/utils/logout-utils'

export function ReEnableUserPage() {
	const navigate = useNavigate()

	const { user, isLoading, logout } = useAuth0()
	const activateAccountMutation = useActivateAccount()
	const { data: deactivatedUser, isLoading: isLoadingDeactivatedUser } = useDeactivatedUser()

	useEffect(() => {
		if (!activateAccountMutation.isPending && activateAccountMutation.isSuccess) {
			navigate('/registration')
		}
	}, [navigate, activateAccountMutation.isPending, activateAccountMutation.isSuccess])

	if (isLoading || isLoadingDeactivatedUser) {
		return <Loading />
	}

	if (!isLoadingDeactivatedUser && !deactivatedUser) {
		return <RedirectPage />
	}

	return (
		<BodyClassName className="bg-primary-50">
			<ThemeColor className="bg-primary-50" />
			<div className="relative flex h-full max-h-full flex-col bg-primary-50 px-4">
				<div className="flex h-full flex-col items-center justify-center gap-4 py-6">
					<h1 className="typography-headline-medium mt-auto text-primary">Account disabled</h1>

					<div>
						<p className="typography-body-medium text-center">
							Your account <span className="text-primary underline">{user?.email + ' '}</span>
							is currently disabled.
						</p>
						<p className="typography-body-medium text-center">
							Just click on the button below in order to re-enable it.
						</p>
					</div>
					<NeedHelpLink />
					<div className="mt-auto flex w-full flex-col gap-2">
						<Button
							onClick={() => activateAccountMutation.mutate()}
							disabled={activateAccountMutation.isPending}
						>
							Re-Enable Account
						</Button>
						<Button
							variant="link"
							onClick={() => logout(getLogoutOptions())}
							disabled={activateAccountMutation.isPending}
						>
							Logout
						</Button>
					</div>
				</div>
			</div>
		</BodyClassName>
	)
}
