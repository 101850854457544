import { useLayoutEffect } from 'react'

import { cn } from '@/lib/utils'

const originalClassName = window.document.body.className

const classNameStack: string[] = []

export default function BodyClassName({ className, children }: { className: string; children?: React.ReactNode }) {
	useLayoutEffect(() => {
		const index = classNameStack.push(cn(className)) - 1

		window.document.body.className = classNameStack[index]

		return () => {
			classNameStack.splice(index, 1)
			window.document.body.className = classNameStack[index - 1] || originalClassName
		}
	}, [className])

	return children || null
}
