import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowLeft } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { Support } from '@/@types/support'
import { useAccounts } from '@/api/hooks/use-accounts'
import { useDeactivateAccount } from '@/api/hooks/use-deactivate-account'
import { Loading } from '@/components/loading'
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	Button,
	Form,
	FormComponent,
	Textarea,
} from '@/components/ui'
import { useAlert } from '@/lib/useAlert.tsx'
import { DashboardLayout } from '@/modules/consumer/dashboard/dashboard-layout.tsx'

const supportFormSchema = z.object({
	notes: z.string().min(10, 'We need more information'),
})

type ConsumerSupport = Pick<Support, 'notes'>

export const DeactivateAccount = () => {
	const deactivateAccountMutation = useDeactivateAccount()
	const [notes, setNotes] = useState('')
	const { triggerSnackbar } = useAlert()
	const [open, setOpen] = useState(false)
	const { data, isLoading: isLoadingAccounts } = useAccounts()
	const navigate = useNavigate()

	const form = useForm<ConsumerSupport>({
		resolver: zodResolver(supportFormSchema),
	})

	if (isLoadingAccounts || !Array.isArray(data)) {
		return <Loading />
	}

	const onConfirmDeactivateAccount = () => {
		deactivateAccountMutation.mutate({ notes })
		setOpen(false)
	}

	const onSubmit = async (values: ConsumerSupport) => {
		const pendingAmount: number = data.reduce((sum, account) => sum + account.PendingAmount, 0)

		if (pendingAmount > 0) {
			triggerSnackbar('Please wait until your transactions are posted and try again', 'error')
			return
		}

		setNotes(values.notes)
		setOpen(true)
	}

	return (
		<DashboardLayout>
			<div className="flex flex-col gap-6">
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className="space-y-md"
					>
						<div className="mb-6 flex justify-between">
							<Button
								data-testid="back-button"
								onClick={() => navigate('/dashboard/settings')}
								type="button"
								variant="secondary"
								size="icon"
							>
								<ArrowLeft />
							</Button>
						</div>
						<h1 className="typography-headline-medium text-primary-500">Deactivate Account</h1>
						<FormComponent
							control={form.control}
							name="notes"
							label="We’re sorry to see you go! Why are you deactivating your account today?"
							required
							render={({ field }) => (
								<Textarea
									data-testid="deactivate-account-reason-textarea"
									placeholder="Tell us what’s on your mind..."
									{...field}
								/>
							)}
						/>
						<p className="text-label-large text-neutral-900">Or email us at support@payboticfinancial.com.</p>
						<Button
							className="w-full text-white"
							size="default"
							variant="destructive"
							type="submit"
							disabled={deactivateAccountMutation.isPending}
						>
							Deactivate Account
						</Button>
						<AlertDialog
							open={open}
							onOpenChange={setOpen}
						>
							<AlertDialogContent>
								<AlertDialogHeader>
									<AlertDialogTitle>Are You Sure?</AlertDialogTitle>
									<AlertDialogDescription>Are you sure you want to deactivate your account?</AlertDialogDescription>
								</AlertDialogHeader>
								<AlertDialogFooter>
									<AlertDialogCancel>Cancel</AlertDialogCancel>
									<AlertDialogAction
										variant="destructive"
										onClick={onConfirmDeactivateAccount}
									>
										Deactivate
									</AlertDialogAction>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialog>
					</form>
				</Form>
			</div>
		</DashboardLayout>
	)
}
