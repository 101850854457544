import { useNavigate } from 'react-router-dom'

import { useTransactions } from '@/api/hooks/use-transaction'
import { TransactionsList } from './TransactionsList'

export function RecentTransactions() {
	const navigate = useNavigate()
	const { data, isLoading, error } = useTransactions(5, 0)

	return (
		<div className="gap-md">
			<h5 className="typography-title-medium mb-4 font-bold text-primary-500">Recent Transactions</h5>
			{error && (
				<p className="typography-body-medium mt-4 rounded-lg border bg-accent p-8 text-center text-black">
					An error occurred while loading transactions
				</p>
			)}
			{data?.items?.length && !error ? (
				<TransactionsList
					data={data?.items || []}
					isLoading={isLoading}
					showSeeMoreButton={data?.page_info.has_more}
					seeMoreOnClick={() => navigate('/dashboard/transactions-history')}
				/>
			) : (
				<p className="typography-body-medium mt-4 rounded-lg border bg-accent p-8 text-center text-black">
					No transactions found
				</p>
			)}
		</div>
	)
}
