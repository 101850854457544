import { useAuth0 } from '@auth0/auth0-react'
import { Home, Landmark, Settings } from 'lucide-react'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { cn } from '@/lib/utils'

type NavigationConfig = {
	label: string
	value: string
	icon: React.ReactElement
	exactValue?: boolean
}

const navigationConfigs: NavigationConfig[] = [
	{ label: 'Home', value: '/dashboard', exactValue: true, icon: <Home className="h-6 w-6" /> },
	{ label: 'Accounts', value: '/dashboard/accounts', icon: <Landmark className="h-6 w-6" /> },
	{ label: 'Settings', value: '/dashboard/settings', icon: <Settings className="h-6 w-6" /> },
] as const

export function Navigation() {
	const navigate = useNavigate()
	const location = useLocation()
	const { user } = useAuth0()

	if (!user?.email_verified) {
		return null
	}

	return (
		<nav className="fixed bottom-0 left-0 w-full border-t border-gray-200 bg-white">
			<div className="mx-auto max-w-screen-xl px-4">
				<div className="flex justify-around md:justify-center">
					{navigationConfigs.map((item) => {
						const isSelected = item.exactValue
							? item.value === location.pathname
							: location.pathname.startsWith(item.value)

						return (
							<button
								key={item.value}
								onClick={() => {
									navigate(item.value)
								}}
								className={cn(
									'group flex flex-col items-center px-2 py-4 text-sm font-medium hover:text-primary active:scale-95 md:w-44',
									isSelected ? 'text-primary' : 'text-gray-500',
								)}
							>
								<span
									className={cn(
										'flex h-8 w-16 items-center justify-center rounded-[100px] group-hover:bg-sidebar-accent',
										isSelected && 'bg-sidebar-accent',
									)}
								>
									{item.icon}
								</span>
								<span className="mt-1">{item.label}</span>
							</button>
						)
					})}
				</div>
			</div>
		</nav>
	)
}
