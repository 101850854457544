import { Link } from 'react-router-dom'

import BodyClassName from '@/components/body-class-name'
import { ErrorDisplay } from '@/components/error-display'
import ThemeColor from '@/components/theme-color'
import { Button } from '@/components/ui'

export const PaymentCancelled = () => {
	return (
		<BodyClassName className="bg-primary-50">
			<ThemeColor className="bg-primary-50" />
			<div className="m-auto flex h-full max-w-lg flex-col p-4 text-center">
				<ErrorDisplay
					title="Transaction Cancelled"
					description="The transaction was cancelled."
					className="my-auto"
				/>

				<div className="space-y-2">
					<Button
						asChild
						variant="destructive"
						className="w-full"
					>
						<Link to="/dashboard">Back To Home</Link>
					</Button>
				</div>
			</div>
		</BodyClassName>
	)
}
