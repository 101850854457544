import { useTheme } from 'next-themes'
import { Toaster as Sonner } from 'sonner'

import { buttonVariants } from '@/components/ui'
import { cn } from '@/lib/utils'

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
	const { theme = 'system' } = useTheme()

	return (
		<Sonner
			cn={cn}
			theme={theme as ToasterProps['theme']}
			className="toaster group"
			toastOptions={{
				unstyled: true,
				classNames: {
					toast: 'flex p-md rounded-md w-80 items-center',
					icon: 'hidden',
					title: 'typography-label-large-prominent',
					description: 'typography-body-medium opacity-90',
					actionButton: buttonVariants({ variant: 'outline', className: 'bg-transparent' }),
					cancelButton: buttonVariants({ variant: 'outline', className: 'bg-transparent' }),
					success: 'bg-success text-success-foreground [&_button]:border-success-foreground',
					warning: 'bg-secondary text-secondary-foreground [&_button]:border-secondary-foreground',
					error: 'bg-destructive text-destructive-foreground [&_button]:border-destructive-foreground',
				},
			}}
			position="bottom-center"
			{...props}
		/>
	)
}

export { Toaster }
