import { useForm } from 'react-hook-form'

import { Button, Form, InputOTP, InputOTPGroup, InputOTPSlot, useFormComponentControlled } from '@/components/ui'
import { cn } from '@/lib/utils'

type Props = {
	callback: (paymentCode: string) => void
}

export const CheckoutEnterCode = ({ callback }: Props) => {
	const form = useForm({ defaultValues: { paymentCode: '' } })
	const FormComponent = useFormComponentControlled(form.control)

	return (
		<Form {...form}>
			<form
				className="mx-auto flex max-w-md flex-col items-center justify-center gap-8 p-4"
				onSubmit={form.handleSubmit(({ paymentCode }) => callback(paymentCode))}
			>
				<p className="typography-body-large">Enter the 5-digit code on the customer’s phone.</p>

				<FormComponent
					required
					name="paymentCode"
					rules={{ required: true, minLength: 5 }}
					render={({ field, fieldState: { error } }) => (
						<InputOTP
							autoFocus
							maxLength={5}
							inputMode="text"
							{...field}
							onChange={(value) => field.onChange(value.toUpperCase())}
						>
							<InputOTPGroup className={cn(error && '*:border-destructive')}>
								<InputOTPSlot index={0} />
								<InputOTPSlot index={1} />
								<InputOTPSlot index={2} />
								<InputOTPSlot index={3} />
								<InputOTPSlot index={4} />
							</InputOTPGroup>
						</InputOTP>
					)}
				/>
				{form.formState.errors.paymentCode && (
					<p className="text-sm text-destructive">Please enter a valid 5-digit code.</p>
				)}
				<Button className="w-full">Submit</Button>
			</form>
		</Form>
	)
}
