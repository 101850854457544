import { useLayoutEffect } from 'react'

export default function ThemeColor({ className }: { className: string }) {
	useLayoutEffect(() => {
		const tempElement = document.createElement('div')
		tempElement.setAttribute('class', className)
		tempElement.setAttribute('style', 'display: none')
		document.body.appendChild(tempElement)

		const themeColor = window.getComputedStyle(tempElement, null).getPropertyValue('background-color')

		document.body.removeChild(tempElement)

		const metaElement = document.createElement('meta')
		metaElement.setAttribute('name', 'theme-color')
		metaElement.setAttribute('content', themeColor)
		document.head.appendChild(metaElement)

		return () => {
			document.head.removeChild(metaElement)
		}
	}, [className])

	return null
}
