import { Link, Navigate } from 'react-router-dom'

import warning from '@/assets/images/warning.svg'
import BodyClassName from '@/components/body-class-name'
import ThemeColor from '@/components/theme-color'
import { Button } from '@/components/ui'
import { useMerchantCheckoutStore } from '@/store/merchant/merchantCheckoutStore'

export function MerchantCheckoutCancelled() {
	const purchase = useMerchantCheckoutStore((state) => state.purchase)
	const reset = useMerchantCheckoutStore((state) => state.reset)

	if (!purchase) {
		return <Navigate to="/merchant/checkout" />
	}

	return (
		<BodyClassName className="bg-neutral-50">
			<ThemeColor className="bg-neutral-50" />
			<div className="h-full bg-neutral-50">
				<div className="container flex h-full w-full max-w-screen-sm flex-col items-center justify-center p-4 md:px-10 md:py-7">
					<img
						src={warning}
						alt=""
						className="mb-2"
					/>
					<h1 className="typography-display-medium max-w-64 text-center text-destructive">Transaction Cancelled</h1>
					<p className="typography-body-large text-primary-900">The transaction was cancelled.</p>

					<Button
						asChild
						className="mt-auto w-full md:mt-8"
						variant="destructive"
					>
						<Link
							to="/merchant/checkout"
							onClick={() => reset()}
						>
							Back To Checkout
						</Link>
					</Button>
				</div>
			</div>
		</BodyClassName>
	)
}
