import { BadgeDollarSign } from 'lucide-react'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import success from '@/assets/images/success.svg'
import BodyClassName from '@/components/body-class-name'
import ThemeColor from '@/components/theme-color'
import { Button } from '@/components/ui'
import { useConsumerPurchaseStore } from '@/store/consumer/consumerPurchaseStore'
import { dateFormatter, timeFormatter } from '@/utils/dates'
import { formatCentsToCurrency } from '@/utils/money-utils.ts'

export const PaymentSuccess = () => {
	const navigate = useNavigate()
	const confirmationStatus = useConsumerPurchaseStore((state) => state.confirmationStatus)

	useEffect(() => {
		if (!confirmationStatus?.purchaseId) {
			navigate('/payment', { replace: true })
		}
	})

	if (!confirmationStatus) {
		return null
	}

	const date = new Date(confirmationStatus.timestamp)

	return (
		<BodyClassName className="bg-primary-50">
			<ThemeColor className="bg-primary-50" />
			<div className="m-auto flex h-full max-w-lg flex-col p-4 pt-20">
				<div className="flex flex-1 flex-col text-primary-900">
					<img
						src={success}
						alt=""
						className="size-20 self-center"
					/>
					<h1 className="typography-display-medium mt-4 text-center text-success">Success!</h1>
					<p className="typography-body-large mt-2 text-center">Your transaction has been approved.</p>
					<div className="mt-12 flex flex-col items-center justify-center space-y-2 rounded-md border border-primary-300 p-lg">
						<BadgeDollarSign />
						<p className="typography-title-medium">Transaction Details</p>
						<p
							className="typography-display-small text-primary"
							data-testid="order-details-value-amount"
						>
							{formatCentsToCurrency(confirmationStatus.amount)}
						</p>
						<p
							className="typography-body-medium"
							data-testid="order-details-value-id"
						>
							ID {confirmationStatus.publicId}
						</p>
						<p
							className="typography-body-medium"
							data-testid="order-details-value-date"
						>
							{dateFormatter.format(date)}, {timeFormatter.format(date)}
						</p>
					</div>
				</div>
				<div className="mt-auto flex flex-col gap-sm">
					<Button
						asChild
						variant="success"
						className="w-full"
					>
						<Link to="/dashboard">Back To Home</Link>
					</Button>
				</div>
			</div>
		</BodyClassName>
	)
}
