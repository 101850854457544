import { Outlet } from 'react-router-dom'

import BodyClassName from '@/components/body-class-name.tsx'
import ThemeColor from '@/components/theme-color.tsx'
import { Header } from './ConsumerHeader.tsx'
import { Navigation } from './ConsumerNavigation.tsx'

export function ConsumerLayout() {
	return (
		<>
			<BodyClassName className="bg-white" />
			<ThemeColor className="bg-white" />
			<Header />
			<main
				className="min-h-full bg-primary-50 pb-24 pt-header"
				data-testid="AuthenticatedLayout-Main"
			>
				<Outlet />
			</main>
			<Navigation />
		</>
	)
}
