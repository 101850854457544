import { createColumnHelper, TableState } from '@tanstack/react-table'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DashboardReportData } from '@/@types/merchant'
import { useDashboardReport } from '@/api/hooks/use-dashboard-report'
import { DataTable, DataTableColumnHeader } from '@/components/data-table'
import { Badge } from '@/components/ui'
import { cn } from '@/lib/utils'
import { dateTimeFormatter } from '@/utils/dates'
import { formatCentsToCurrency } from '@/utils/money-utils'

const columnHelper = createColumnHelper<DashboardReportData['items'][number]>()

const columns = [
	columnHelper.accessor('DateTimeCreated', {
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Date"
			/>
		),
		cell: ({ cell }) => dateTimeFormatter.format(new Date(cell.getValue())),
		enableSorting: false,
		meta: {
			className: cn('w-1/4 text-nowrap'),
		},
	}),
	columnHelper.accessor('CustomerName', {
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Customer"
			/>
		),
		enableSorting: false,
		meta: {
			className: cn('text-nowrap'),
		},
	}),
	columnHelper.accessor('BaseAmount', {
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Sales"
			/>
		),
		cell: ({ cell }) => formatCentsToCurrency(cell.getValue()),
		enableSorting: false,
		meta: {
			className: 'text-right',
		},
	}),
	columnHelper.accessor('TransactionStatus', {
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Status"
			/>
		),
		cell: ({ cell }) => {
			const value = cell.getValue()
			return (
				<Badge
					variant={
						value.toLowerCase() === 'posted'
							? 'secondary'
							: value.toLowerCase() === 'pending'
								? 'default'
								: 'destructive'
					}
				>
					{value}
				</Badge>
			)
		},
		enableSorting: false,
	}),
]

export function MerchantRecentTransactionsTable() {
	const [searchParams, setSearchParams] = useSearchParams()
	const page = parseInt(searchParams.get('page') || '1')
	const pageSize = parseInt(searchParams.get('pageSize') || '10')

	const { data, isLoading } = useDashboardReport({
		limit: pageSize,
		offset: (page - 1) * pageSize,
	})

	const onPaginationChange = useCallback(
		({ pageIndex, pageSize }: TableState['pagination']) => {
			setSearchParams({ page: (pageIndex + 1).toString(), pageSize: pageSize.toString() })
		},
		[setSearchParams],
	)

	return (
		<DataTable
			isLoading={isLoading}
			columns={columns}
			data={data?.items ?? []}
			rowCount={data?.page_info.total ?? 0}
			pagination={{
				state: {
					pageIndex: page - 1,
					pageSize: pageSize,
				},
				onChange: onPaginationChange,
			}}
		/>
	)
}
