import { useAuth0 } from '@auth0/auth0-react'
import dayjs from 'dayjs'
import { ArrowLeftIcon } from 'lucide-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { useCreateUser } from '@/api/hooks/use-create-user'
import { useDeactivatedUser } from '@/api/hooks/use-get-deactivated-user'
import { useUser } from '@/api/hooks/use-user'
import { Loading } from '@/components/loading'
import { Button, Progress } from '@/components/ui'
import { cn } from '@/lib/utils'
import HomeAddressForm from '@/modules/consumer/registration/HomeAddress'
import PersonalInformationForm from '@/modules/consumer/registration/PersonalInformation'
import TermsAndConditionsForm from '@/modules/consumer/registration/TermsAndConditions'
import VerifyEmail from '@/modules/consumer/registration/VerifyEmail'
import { UserSchema } from '@/utils/validations'

type UserData = Partial<z.infer<typeof UserSchema>>

const steps = [PersonalInformationForm, HomeAddressForm, TermsAndConditionsForm, VerifyEmail] as const

export function RegistrationPage() {
	const navigate = useNavigate()
	const { user: auth0User, isLoading, getAccessTokenSilently } = useAuth0()
	const user = useUser()
	const {
		data: deactivatedUser,
		isLoading: isLoadingDeactivatedUser,
		status: statusGetDeactivatedUser,
	} = useDeactivatedUser()

	const [userData, setUserData] = useState<Partial<UserData>>({})
	const [currentStep, setCurrentStep] = useState(0)

	const createUserMutation = useCreateUser()

	useEffect(() => {
		if (isLoading || user.isLoading || !auth0User || currentStep !== 0) {
			return
		}
		if (user.data && !auth0User.email_verified) {
			setCurrentStep(steps.length - 1)
		}
	}, [auth0User, auth0User?.email_verified, isLoading, user.data, user.isLoading, currentStep])

	useEffect(() => {
		if (isLoading || currentStep !== 0 || isLoadingDeactivatedUser || statusGetDeactivatedUser !== 'success') {
			return
		}

		if (deactivatedUser) {
			navigate('/re-enable-user')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoadingDeactivatedUser])

	const onSubmitStep = useCallback(
		async (data: Partial<UserData>) => {
			const updatedUserData = { ...userData, ...data }

			setUserData(updatedUserData)

			if (currentStep === steps.length - 2) {
				try {
					await createUserMutation.mutateAsync({
						...updatedUserData,
						date_of_birth: dayjs(updatedUserData.date_of_birth, 'MM/DD/YYYY').toISOString(),
						email: auth0User?.email,
					})
					await getAccessTokenSilently({ cacheMode: 'off' })
					setCurrentStep((currentStep) => currentStep + 1)
				} catch (error) {
					console.error(error)
				}
			} else {
				setCurrentStep((currentStep) => currentStep + 1)
			}
		},
		[auth0User?.email, createUserMutation, currentStep, getAccessTokenSilently, userData],
	)

	const StepComponent = steps[currentStep]

	const progressValue = useMemo(() => ((currentStep + 1) / steps.length) * 100, [currentStep])

	if (isLoading || user.isLoading) {
		return <Loading />
	}

	if (auth0User?.email_verified && user.data) {
		return <Navigate to="/redirect" />
	}

	return (
		<main className="flex h-full w-full flex-col">
			<div className="w-full bg-white">
				<div className="mx-auto w-full space-y-2 px-4 pb-6 pt-2 text-right md:max-w-2xl lg:max-w-3xl">
					<div className="flex items-center">
						<Button
							variant="secondary"
							size="icon"
							className={cn('rounded-full', (currentStep === 0 || currentStep === steps.length - 1) && 'invisible')}
							onClick={() => setCurrentStep(currentStep - 1)}
						>
							<ArrowLeftIcon />
						</Button>

						<div className="typography-label-large ml-auto font-semibold text-primary">
							Step {currentStep + 1} of {steps.length}
						</div>
					</div>
					<Progress value={progressValue} />
				</div>
			</div>
			<div className="mx-auto flex min-h-0 w-full flex-1 flex-col *:h-full md:max-w-2xl lg:max-w-3xl">
				{StepComponent && (
					<StepComponent
						defaultValues={userData}
						onSubmit={onSubmitStep}
					/>
				)}
			</div>
		</main>
	)
}
